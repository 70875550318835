import * as React from "react";

import Layout from "../components/layout";
import SecondaryClouds from "../components/secondary-clouds";
import { HelmetDatoCms } from "gatsby-source-datocms";
import { useI18next } from "gatsby-plugin-react-i18next";

import { graphql } from "gatsby";
import DynamicLink from "../components/link";

import Faq from "../components/faq";

const Support = ({ data }) => {
  const { language } = useI18next();
  return (
    <Layout>
      <HelmetDatoCms seo={data.support.seoMetaTags} />
      <main className="support-faq-page">
        <section className="support-faq-section" id="support">
          <h1 className="prewrap">{data.support.supportTitle}</h1>
          <div className="support-grid">
            {data.support.supportBox.map((support) => (
              <div className="support-box">
                <div className="support-box-titles">
                  <h6>{support.subtitle}</h6>
                  <h5 className="bold">{support.title}</h5>
                </div>
                <DynamicLink
                  href={support.btnUrl}
                  classes="btn btn--purple"
                  type="button"
                  text={support.btnTxt}
                  language={language}
                />
              </div>
            ))}
          </div>
        </section>
        <section className="support-faq-section" id="faq">
          <h1 className="prewrap">{data.faq.faqTitle}</h1>
          <Faq data={data.faq.faqItem} />
        </section>
        <SecondaryClouds />
      </main>
    </Layout>
  );
};

export default Support;

export const query = graphql`
  query SupportPage($language: String!) {
    support: datoCmsSupportFaq(locale: { eq: $language }) {
      supportTitle
      supportBox {
        subtitle
        title
        btnTxt
        btnUrl
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    faq: datoCmsSupportFaq(locale: { eq: $language }) {
      faqTitle
      faqItem {
        id
        question
        answer
      }
    }
  }
`;
